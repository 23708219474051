import * as React from 'react'
import { useEffect } from 'react'
import ReactModal from 'react-modal'
import './SizeChartModal.css'

const modalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '1200px',
    padding: '0',
    border: '0',
  },
  overlay: {
    zIndex: 10000,
    backgroundColor: 'rgba(75,75,75,0.79)',
  },
}

const SizeChartModal = () => {
  useEffect(() => {
    ReactModal.setAppElement('#page')
  }, [])

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  function openModal() {
    setIsModalOpen(true)
  }

  function closeModal() {
    setIsModalOpen(false)
  }

  return (
    <>
      <button
        aria-label="Open size chart"
        className="underline font-bold"
        onClick={openModal}
      >
        What size do I need?
      </button>
      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="modal"
      >
        <div className="fixed top-2 right-2">
          <button
            onClick={closeModal}
            aria-label="close modal"
            data-testid="close-modal-button"
          >
            &#x2715;
          </button>
        </div>
        <div>
          <div className="bg-white max-w-max p-8">
            <table className="SizeChart" data-testid="size-chart">
              <thead>
                <tr>
                  <th></th>
                  <th>XS</th>
                  <th>S</th>
                  <th>M</th>
                  <th>L</th>
                  <th>XL</th>
                  <th>2XL</th>
                  <th>3XL</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>Body Length</th>
                  <td>27 ¾</td>
                  <td>28 ¾</td>
                  <td>29 ¾</td>
                  <td>30 ¾ </td>
                  <td>31 ¾</td>
                  <td>32 ¾</td>
                  <td>33 ¾</td>
                </tr>
                <tr>
                  <th>Chest Width (laid flat)</th>
                  <td>16 ½</td>
                  <td>18</td>
                  <td>20</td>
                  <td>22</td>
                  <td>24</td>
                  <td>26</td>
                  <td>28</td>
                </tr>
                <tr>
                  <th>Sleeve Length</th>
                  <td>8 ⅛</td>
                  <td>8 ⅜</td>
                  <td>8 ⅝</td>
                  <td>8 ⅞</td>
                  <td>9 ⅛</td>
                  <td>9 ⅜</td>
                  <td>9 ⅝</td>
                </tr>
                <tr>
                  <th>Neck Size</th>
                  <td>6</td>
                  <td>6 ½</td>
                  <td>6 ¾</td>
                  <td>7</td>
                  <td>7 ½</td>
                  <td>7 ¾</td>
                  <td>7 ¾</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </ReactModal>
    </>
  )
}

export default SizeChartModal

import * as React from 'react'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Layout from 'components/Layout'
import ShopProduct from '../../components/Shop/ShopProduct/ShopProduct'
import Seo from '../../components/Seo'

export interface IMedia {
  id: string
  image: { gatsbyImageData: IGatsbyImageData }
}

export interface IProductProps {
  handle: string
  title: string
  description: string
  descriptionHtml: string
  featuredImage: { gatsbyImageData: IGatsbyImageData }
  priceRangeV2: {
    maxVariantPrice: {
      amount: number
    }
  }
  variants: {
    title: string
    inventoryQuantity: number
    availableForSale: boolean
    storefrontId: string
  }[]
  options: {
    name: string
    values: string[]
    shopifyId: string
  }[]
  media: IMedia[]
}

const ShopifyProduct = ({ pageContext }: { pageContext: IProductProps }) => {
  const {
    title,
    description,
    featuredImage,
    priceRangeV2,
    options,
    variants,
    media,
    descriptionHtml,
    handle,
  } = pageContext

  return (
    <Layout>
      <ShopProduct
        handle={handle}
        title={title}
        description={description}
        descriptionHtml={descriptionHtml}
        featuredImage={featuredImage}
        priceRangeV2={priceRangeV2}
        variants={variants}
        options={options}
        media={media}
      />
    </Layout>
  )
}

export default ShopifyProduct

export const Head = ({ pageContext }: { pageContext: IProductProps }) => {
  const { title, description, featuredImage } = pageContext
  return (
    <Seo
      title={title}
      description={description}
      image={featuredImage.gatsbyImageData.images.fallback?.src}
    />
  )
}
